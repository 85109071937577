<template>
    <div>
      <div class="text-activity-notes text-left" v-if="this.currentEntry.service == undefined || this.currentEntry.notToBill == undefined">
        <h4 class="subtitle-form mb-2">{{ $t("components.services.service") }}</h4>
        {{ $t("activities.multiple.details.service") }}
      </div>
      <div v-else>
        <b-row>
          <b-col cols="8">
            <h4 class="subtitle-form mb-2">{{ $t("components.services.service") }}</h4>
            <div class="text-infos bg-white" >
              <span v-if="service_name" > {{ service_name }} </span>
              <span v-else> {{ $t("components.services.no_service") }} </span>
            </div>
          </b-col>
          
          <b-col clos="4">
            <h4 class="subtitle-form mb-2">{{ $t("components.services.not_to_bill") }}</h4>
            <b-form-checkbox 
              size="sm" 
              v-model="notToBill" 
              disabled
            />
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col cols="8">
            <div>
              <h4 class="subtitle-form mb-2">{{ $t("activities.details.billingDate") }}</h4>
              <span class="text-infos bg-white">{{ this.currentEntry.billingDate ? formatDate(this.currentEntry.billingDate, opt="date") : $t("empty_information")}}</span>
            </div> 
          </b-col>
        </b-row> -->
      </div>
    </div>
  </template>
  
  <script>
  import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
  import {formatDate} from "@/utils/commons";
  export default {
    mixins: [DetailUpdateMixin],
    data() {
      return {
      };
    },
    components: {},
    computed: {
        service_name() {
            return this.currentEntry.service_name;
        },
        notToBill(){
          return this.currentEntry.notToBill;
        },
        billingDate(){
          return this.currentEntry.billingDate;
        },
    },
    methods:{
      formatDate,
    },  
  };
  </script>
  
  <style lang="scss" scoped>
  img {
    width: 1em;
    float: left;
    margin-right: 5px;
  }
  
  /**
   * Text params for empty attribut.
   */
  .enpty-attr {
    color: $danger-color;
  }
  
  /**
   * Change notes params.
   */
  .machines-params {
    border-radius: 6px;
    padding: 0px 8px 0px 0px;
  }
  
  /**
   * Change the subtitle color.
   */
  .change-subtitle-color {
    color: $primary-color;
  }
  </style>
  